import React from "react"
import { graphql } from "gatsby"

import MatchHistoryPage from "../../templates/match-history"

import { messiClubs } from "../../data/messi"
import { messiLeagues } from "../../data/messi"


class MessiMatchHistory extends React.Component {

  render() {

    let mdata = this.props.data.allSheetMessiMatchHistory.edges.filter(d => d.node.date)

    return (
      <MatchHistoryPage
        data={mdata}
        player="Messi"
        playerNameFull="Lionel Messi"
        playerCountry="Argentina"
        playerLeagues={messiLeagues}
        playerClubs={messiClubs}
        slug="messi-match-history"
      />
    )
  }
}
  

export const query = graphql`
  query {
    allSheetMessiMatchHistory {
      edges {
        node {
          date
          year
          season
          competition
          homeAway
          round
          team
          opponent
          scoreTeam
          scoreOpponent
          goals
          assists
          started
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          rating
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          allAssists
          ftScore
          penScore
          shootout
          h2H
          opponentLeaguePosition
          notes
        }
      }
    }
  }
`

export default MessiMatchHistory
